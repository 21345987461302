<template>
  <div id="app" class="chat-app">
    <particles/>
  </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
import particles from './components/particles'

export default {
  name: 'App',
  components: {
    //HelloWorld,
    particles
  },
  mounted() {
    this.$nextTick(() => {
      document.addEventListener('DOMContentLoaded', function () {
    console.log("document====",document);
    setTimeout(() => {
      //document.querySelector('.zoe-template-wrap .zoe-template3').click()
      document.getElementsByClassName('zoe-template-toggle-icon')[0].click();
      var element = document.getElementsByClassName("embed-chat-hld");
      if(element){
          element[0].classList.add("left-center-chat");
      }
      }, 3000)
});
    
      });
  }
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
body{
  margin: 0 !important;
}
.chat-app{
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height:100vh;
  margin:0;
  background: url("https://firebasestorage.googleapis.com/v0/b/uate2monair.appspot.com/o/main-banner-1.jpg?alt=media") no-repeat center center / cover;
  overflow: hidden;
  position: relative;
}
.chat-app .qkb-bot-ui{
  position: absolute !important;
  right: 50% !important;
  transform: translateX(169px);
}
.chat-app .qkb-msg-bubble-component__text {
    word-break: break-all !important;
}
.left-center-chat{
  left: calc(50% - 200px) !important;
}
@media only screen and (max-width:767px) {
  .chat-app .qkb-bot-ui {position: absolute !important; right: 0% !important; transform: translateX(-7px);}
  .chat-app .particles{height: 100%;}
  }
</style>
